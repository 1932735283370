html,
body,
#app {
  height: 100%;
}
.app-store-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-store-buttons {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;

  h1 {
  }

  img {
    max-width: 300px;
  }
}
