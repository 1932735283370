//@import "./colors.scss";

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Raleway, Roboto, sans-serif;

  //color: $color-blue-500;
  color: #1d3554;
  background-color: #f8f8fa;
}

//h1 {
//  font-weight: 500;
//  margin: 0;
//
//  &.page-title {
//    margin-bottom: 25px;
//  }
//}

a {
  color: #1d3554;
  text-decoration: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
*:focus {
   outline: none;
 }

.content-editor {
  .section {
    margin-bottom: 5px;
    .buttons button {
      opacity: 0;
    }
    &:hover .buttons button {
      opacity: 1;
    }
  }
  input, textarea {
    border: none;
    width: 100%;
    font-size: 12pt;
    line-height: 1.4;
  }
  .heading {
    font-weight: bold;
    font-size: 18pt;
  }
  img {
    width: 100%;
    //max-height: 400px;
  }
  .buttons {
    text-align: center;
  }
}